
import React from 'react'
import internationalpatientbanner from '../../assets/international-patient/internationalpatientbanner.jpg'
import TopBanner from '../../components/common/TopBanner'
import HospitalHighlights from '../../components/InternationalPatientCare/HospitalHighlights'
import PictureGallery from '../../components/InternationalPatientCare/PictureGallery'
import MedicalSpecialities from '../../components/InternationalPatientCare/MedicalSpecialities'
import TicketBooking from '../../components/InternationalPatientCare/TicketBooking'
import PatientFacilities from '../../components/InternationalPatientCare/PatientFacilities'
import VisitingSights from '../../components/InternationalPatientCare/VisitingSights'
import ImageContent from '../../components/InternationalPatientCare/ImageContent'
import CardSection from '../../components/InternationalPatientCare/ImageCardGrid'
import CentreofExcellence from '../../components/InternationalPatientCare/CentreofExcellence'
import EstimateForm from '../../components/InternationalPatientCare/EstimateForm'
import InternationalServices from '../../components/InternationalPatientCare/InternationalServices'
import OurProcess from '../../components/InternationalPatientCare/OurProcess'
import Faqs from '../../components/InternationalPatientCare/Faqs'
import Specialties from '../../components/home/specialities/Specialties'
import ImageCardGrid from '../../components/InternationalPatientCare/ImageCardGrid'

const InternationalPatientPage = () => {
  return (
    <div>
      <TopBanner title=" International Patient Service Department " image={internationalpatientbanner} />
      
      <ImageContent/>
      <ImageCardGrid/>
      <HospitalHighlights/>
      <CentreofExcellence/>
      <EstimateForm/>
      <InternationalServices/>
      <OurProcess/>
      <Specialties/>
      <PictureGallery/>
      <Faqs/>
      {/* <MedicalSpecialities/>
      <TicketBooking/>
      <PatientFacilities/>
      <VisitingSights/> */}
    </div>
  )
}

export default InternationalPatientPage
