import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";

const Faqs = () => {
  const faqs = [
    { question: "What special facilities and services do you provide for international patients?", answer: "At SP Medifort Hospital, we offer dedicated international patient services, including medical consultations, treatment planning, visa assistance, airport pickup and drop-off, language interpretation, accommodation arrangements, and post-treatment care. Our team ensures a seamless experience, allowing you to focus solely on your recovery.    " },
    { question: "How do I book an appointment with a doctor?     ", answer: "You can book an appointment through our International Patient Services team via email, phone, or our online portal. Our coordinators will assist in scheduling consultations with the appropriate specialists and ensure minimal waiting time.    " },
    { question: "Where will I stay before admission or after being discharged? ", answer: "For international patients, we provide assistance in arranging comfortable stays at partner hotels, guest houses, or serviced apartments near the hospital. Our team will help you find accommodation that suits your budget and preferences.    " },
    { question: "Do you arrange airport pickup and transportation?     ", answer: "Yes, we offer complimentary airport pickup and drop-off services for international patients. Our team will coordinate your arrival and ensure a hassle-free transfer to your accommodation or the hospital." },
    { question: "Can you assist me in obtaining a medical visa? ", answer: "Yes, we provide visa assistance by issuing an invitation letter for medical treatment, which you can submit to the Indian embassy in your country. Our team will guide you through the visa application process and any required documentation.    " },
    { question: "What are the nearby tourist attractions I can explore during my stay? ", answer: "Trivandrum, the capital city of Kerala, offers several must-visit attractions, including:",
list:[
    { bold: "Kovalam Beach", text: " - Famous for its golden sand and stunning sunsets." },
        { bold: "Padmanabhaswamy Temple", text: " - A historic temple known for its intricate architecture." },
        { bold: "Napier Museum & Art Gallery", text: " - Showcasing ancient artifacts and paintings." },
        { bold: "Thiruvananthapuram Zoo", text: " - One of the oldest zoos in India, home to a variety of animals.        " },
        {bold:" Additionally, Kanyakumari",text:"-  just a short drive away, features iconic landmarks such as the Vivekananda Rock Memorial, Thiruvalluvar Statue, and Kanyakumari Beach."}
] },
    { question: "Do you provide interpreters for international patients?     ", answer: "Yes, we have language interpreters available to assist patients who speak Arabic, French, Russian, and other languages. Our interpreters will help you communicate effectively with our medical team.    " },
    { question: "Can my family accompany me during my treatment?     ", answer: "Yes, we encourage family members to accompany international patients. Our team can assist in arranging accommodation and provide support for their stay.    " },
    { question: " Do you provide assistance with local travel and sightseeing?     ", answer: "Yes, our International Patient Services team can help arrange local transportation and guided tours for sightseeing in Trivandrum and nearby attractions like Kanyakumari.    " },
    { question: " How do I make payments for my treatment?     ", answer: "We accept multiple payment methods, including international bank transfers, credit/debit cards, and cash payments in Indian Rupees (INR). Our financial counselors will assist you with cost estimates and payment options.    " },
];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="max-w-4xl mx-auto py-6 md:py-12 px-4">
      {/* Heading */}
      <h1 className="font-bold lg:mb-0 mb-6 text-[32px] md:text-headings text-primary_color font-sans z-20 max-w-common mx-auto lg:pb-10">
        FAQs 
        <span className="block w-full flex justify-center items-center relative">
          <span className="w-5 h-[2px] bg-black"></span>
          <span className="w-3 h-3 bg-black rounded-full mx-2"></span>
          <span className="w-5 h-[2px] bg-black"></span>
        </span>
      </h1>

      {/* FAQ Section */}
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="border border-gray-300 shadow-md rounded-lg p-3 cursor-pointer transition-all duration-300"
            onClick={() => toggleAccordion(index)}
          >
            {/* Question Section */}
            <div className="flex justify-between items-center">
              <div className="flex flex-row gap-2">
                <h3 className="text-[18px]">{faq.question}</h3>
                <span>
                  <FaChevronDown className={`text-xl text-primary_color font-bold transform transition-transform ${
                    openIndex === index ? "rotate-180" : "rotate-0"
                  }`} />
                </span>
              </div>
            </div>

            {/* Answer Section */}
            {openIndex === index && (
              <div className="mt-2 text-gray-700 text-left bg-gray-100 p-2">
                <p>{faq.answer}</p>
                {/* If the FAQ has a list, render it */}
                {faq.list && (
                  <ul className="mt-2 space-y-2 pl-5 list-none">
                    {faq.list.map((item, i) => (
                      <li key={i} className="flex items-start gap-2">
                        <span className="w-2 h-2 bg-black rounded-full mt-2"></span>
                        <span>
                          <strong>{item.bold}</strong>{item.text}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faqs;