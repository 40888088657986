import React, { useEffect, useRef, useState } from "react";
import centreofexcellence from "../../assets/international-patient/centreofexcellence.jpeg";
import { Link } from "react-router-dom";
import axios from "../../api/axios";
import { departmentsRoute } from "../../utils/Endpoint";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import DeptCard from "./Deptcard";

const CentreofExcellence = () => {
  const [departments, setDepartments] = useState([]);
  const scrollRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    const getDepts = async () => {
      try {
        const res = await axios.get(departmentsRoute);
        if (res.status === 200) {
          setDepartments(res.data.result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getDepts();
  }, []);

  // Function to scroll left or right
  const scroll = (direction) => {
    if (scrollRef.current) {
      const scrollAmount = 300; // Adjust scroll amount
      scrollRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  // Auto-scroll functionality
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      scroll("right"); // Scrolls right automatically
    }, 3000); // Adjust time interval (in milliseconds)

    return () => clearInterval(intervalRef.current); // Cleanup on unmount
  }, []);

  // Pause auto-scroll on hover
  const handleMouseEnter = () => clearInterval(intervalRef.current);
  const handleMouseLeave = () => {
    intervalRef.current = setInterval(() => scroll("right"), 3000);
  };

  return (
    <div className="relative w-full h-[600px] flex flex-col items-center justify-center">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${centreofexcellence})`,
          backgroundAttachment: "fixed",
        }}
      />

      {/* Overlay Effect */}
      <div className="absolute inset-0 bg-primary_color bg-opacity-60 contrast-125 backdrop-saturate-0" />

      {/* Content Section */}
      <div className="relative z-10 w-full mx-auto flex flex-col items-center">
        <h1 className="text-[32px] -mt-10 text-white ">
          Centre of Excellence
        </h1>

        {/* Scrollable Cards */}
        <div
          ref={scrollRef}
          className="flex lg:gap-0 gap-4 overflow-x-auto scrollbar-hide scroll-smooth w-full px-2 py-2 mt-10"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {departments?.map((item) => (
            <Link
            key={item?._id}
            to={`/departments/detailed-view/${item?._id}`}
            className="md:min-w-auto min-w-[200px] md:min-w-[230px]"
          >
            <DeptCard data={item} />
          </Link>
          
          ))}
        </div>

        {/* Navigation Buttons */}
        <div className="flex justify-center gap-5 mt-4">
          <button
            className="p-2 bg-transparent text-white border border-white rounded-full hover:bg-gray-400"
            onClick={() => scroll("left")}
          >
            <MdNavigateBefore size={32} />
          </button>
          <button
            className="p-2 bg-transparent text-white border border-white rounded-full hover:bg-gray-400"
            onClick={() => scroll("right")}
          >
            <MdNavigateNext size={32} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CentreofExcellence;
