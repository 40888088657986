import React, { useState } from "react";

const EstimateForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    age: "",
    gender: "",
    countryCode: "",
    contactNumber: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted:", formData);
  };

  return (
    <div className="md:py-20 py-10">
    <div className="max-w-6xl mx-auto bg-[#852065] bg-opacity-[8%]  lg:p-10 md:p-8 p-6 ">
      <h2 className="text-[25px]  text-left mb-8">
        Get a personalised treatment plan and estimate
      </h2>

      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Row 1: Name, Age, Gender */}
        <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
          <div className="flex flex-col w-full">
            <label className="text-[17px]  text-left mb-2">Name of Patient</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="border p-2 rounded w-full"
            />
          </div>

          <div className="flex flex-col w-full">
            <label className="text-[17px] text-left mb-2">Age</label>
            <input
              type="number"
              name="age"
              value={formData.age}
              onChange={handleChange}
              required
              className="border p-2 rounded w-full bg-white"
            />
          </div>

          <div className="flex flex-col w-full">
            <label className="text-[17px] mb-2 text-left">Gender</label>
            <select
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              required
              className="border p-2 rounded w-full bg-white"
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
        </div>

        {/* Row 2: Country Code, Contact Number, Country */}
        <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
          <div className="flex flex-col w-full">
            <label className="text-[17px] mb-2 text-left">Country Code</label>
            <input
              type="text"
              name="countryCode"
              value={formData.countryCode}
              onChange={handleChange}
              required
              className="border p-2 rounded w-full"
            />
          </div>
          <div className="flex flex-col w-full">
          <label className="text-[17px] mb-2 text-left">Contact Number</label>
          <input
            type="tel"
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleChange}
            placeholder="Your Mobile Number"
            required
            className="border p-2 rounded w-full"
          />
</div>
<div className="flex flex-col w-full">
          <label className="text-[17px] mb-2 text-left">Country</label>
          <input
            type="text"
            name="country"
            value={formData.country}
            onChange={handleChange}
            placeholder="Country"
            required
            className="border p-2 rounded w-full"
          />
          </div>
        </div>

        {/* Row 3: Message */}
        <div className="flex flex-col">
          <label className="text-[17px] mb-2 text-left">Message</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            className="border p-2 rounded w-full h-24"
          ></textarea>
        </div>

        {/* Row 4: Full-width Button */}
        <button
          type="submit"
          className="w-full bg-primary_color text-white font-semibold py-2 rounded-xl transition"
        >
          Enquire Now
        </button>
      </form>
    </div>
    </div>
  );
};

export default EstimateForm;
