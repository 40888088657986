import React from "react";
import internationalpoints from '../../assets/international-patient/internationalpoints.jpeg'

const InternationalServices = () => {
  const items = [
    "Ticket booking ", "Hotel stay", "Translator services (Arabic, Russian, Mali etc.)",
     "F&B multi-cuisine available ", "Sightseeing around Trivandrum ","Local Taxi services     ",
     "Free Airport pick & drop ","Patient convenience  ","Costa Coffee",
     "Prayer room","Exclusive help desk","1 km from international airport",

  ];

  return (
    <div
      className="relative w-full lg:h-[800px] md:h-[700px] h-[1000px] flex items-center justify-center text-white px-6 py-12"
      style={{
        backgroundImage: `url(${internationalpoints})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >

      {/* Overlay Effect */}
      <div className="absolute inset-0 bg-primary_color bg-opacity-60 contrast-125 backdrop-saturate-0" />
      {/* Content */}
      <div className="absolute lg:max-w-5xl mx-auto w-full text-center py-10 p-2">
        {/* Heading */}
        <h2 className="relative text-[32px] mb-10 -mt-28 md:py-10 py-5 text-white text-center">
          Our International Services Includes :
          <span className="block w-full flex justify-center items-center mt-2 relative">
            <span className="w-24 h-[2px] bg-white"></span>
            <span className="w-3 h-3 bg-white rounded-full mx-2"></span>
            <span className="w-24 h-[2px] bg-white"></span>
          </span>
        </h2>


        {/* Grid List */}
        <div className="grid md:grid-cols-2 grid-cols-1 lg:gap-20 gap-10 text-left">
          {/* First column with 9 items */}
          <div>
            {items.slice(0, 6).map((item, index) => (
              <div key={index}>
                <p className="flex items-center gap-3 text-lg ">
                  <span className="text-xl font-bold">&gt;</span> {item}
                </p>
                <hr className="border-gray-300 my-2 opacity-50 " />
              </div>
            ))}
          </div>

          {/* Remaining items split into two columns */}
          <div>
            {items.slice(6).map((item, index) => (
              <div key={index}>
                <p className="flex items-center gap-3 text-lg">
                  <span className="text-xl font-bold">&gt;</span> {item}
                </p>
                <hr className="border-gray-300 my-2 opacity-50" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternationalServices;
