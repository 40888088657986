import React from "react";
import '../../components/home/department/Department.css';

const DeptCard = ({ data }) => {
  return (
    <div
      id="department"
      className="w-[190px] h-[155px] bg-white max-md:w-full rounded-xl relative shadow-xl cursor-pointer overflow-hidden"
    >
      
      <div className="w-full h-full absolute z-10 top-0 rounded-2xl flex flex-col  items-center justify-center gap-3">
        <img src={data?.logo?.location} alt="icon" className="w-14 bg-primary_color rounded-full p-2" />
        <div className="lower text-[13px] px-3">{data?.dept_name}</div>
      </div>
    </div>
  );
};

export default DeptCard;
