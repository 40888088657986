import React from "react";
import jciaccreditedsp from '../../assets/international-patient/jciaccreditedsp.jpeg'
import kneetransplantsp from '../../assets/international-patient/kneetransplantsp.jpg'
import roboticsurgerysp from '../../assets/international-patient/roboticsurgerysp.jpg'
import cardiacsp from '../../assets/international-patient/cardiacsp.jpg'

const highlights = [
  {
    id: 1,
    title: "First JCI-Accredited Hospital in Kerala",
    image: jciaccreditedsp, // Replace with actual image path
  },
  {
    id: 2,
    title: "First to Perform Total Knee Replacement (TKR)",
    image: kneetransplantsp,
  },
  {
    id: 3,
    title: "First to Introduce Robotic Surgery in the Region",
    image: roboticsurgerysp,
  },
  {
    id: 4,
    title: "Leading in Cardiac Care with 1000+ Successful Surgeries",
    image: cardiacsp,
  },
];

const HospitalHighlights = () => {
  return (
    <section className="md:py-12 py-6 bg-gray-100 ">
      <div className="max-w-common mx-auto px-4 text-center">
        <h2 className="text-[32px]  font-bold text-gray-800  mt-10">Our Highlights</h2>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6 mt-10 md:mt-16 mb-10">
          {highlights.map((highlight) => (
            <div
              key={highlight.id}
              className="bg-white rounded-2xl shadow-lg overflow-hidden hover:scale-105 transition-transform"
            >
              <img
                src={highlight.image}
                alt={highlight.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-4 text-gray-700 text-lg font-semibold">
                {highlight.title}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HospitalHighlights;
