import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import generalwardsp from '../../assets/international-patient/generalwardsp.webp'
import cafteria from '../../assets/international-patient/cafteria.png'
import image3 from '../../assets/gallery/image 3.jpg'
import image4 from '../../assets/gallery/image 4.jpg'
import image2 from '../../assets/gallery/image 2.jpg'
import generalward from '../../assets/international-patient/generalward.jpeg'

const galleryImages = [
    { id: 1, title: "Presidential Suite", image: image3},
    { id: 2, title: "Deluxe Room", image: image2 },
    { id: 3, title: "General Ward", image: generalward },
    { id: 4, title: "Waiting Lounge & Cafeteria", image: cafteria },
    { id: 5, title: "Operation Theatre & ICU", image: image4 },
  ];
  const PictureGallery = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,  // Enable arrows
        autoplay: true,
        autoplaySpeed: 2000, // Auto-scroll every 2 seconds
        responsive: [
          { breakpoint: 1024, settings: { slidesToShow: 2, slidesToScroll: 1 } },
          { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1 } },
        ],
      };
      
  
    return (
      <section className="py-12 bg-white">
        <div className="max-w-common mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">Picture Gallery</h2>
          <Slider {...settings}>
            {galleryImages.map((image) => (
              <div key={image.id} className="px-2 mt-5">
                <img
                  src={image.image}
                  alt={image.title}
                  className="w-full h-60 object-cover rounded-xl shadow-lg"
                />
                <div className="text-lg font-semibold text-gray-700 mt-2">
                  {image.title}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    );
  };
  
  export default PictureGallery 
  