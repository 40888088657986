import React from 'react'
import ourprocessimage from '../../assets/international-patient/ourprocessimage.png'

const OurProcess = () => {
  return (
    <div className='md:py-20 py-10 max-w-common mx-auto'>
       <h1 className="font-bold text-[32px] md:text-headings font-sans z-20 max-w-common  mx-auto lg:pb-10">
          Our <span className="text-primary_color">Process</span> 
          <span className="block w-full flex justify-center items-center  relative">
    <span className="w-10 h-[2px] bg-black"></span>
    <span className="w-3 h-3 bg-black rounded-full mx-2"></span>
    <span className="w-10 h-[2px] bg-black"></span>
  </span>
        </h1>

      <img src={ourprocessimage} className='mt-12'></img>
    </div>
  )
}

export default OurProcess
