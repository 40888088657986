import React from 'react';
// import grayLogo from "@/assets/logo/gray_logo.png";
import grayLogo from "../../assets/logo/gray_logo.png";
import avatar from "../../assets/doctors/avatar.png";
import { useTranslation } from 'react-i18next';
import { useNavigate} from 'react-router-dom';


const DocPrimeCard = ({data}) => {
    const { t } = useTranslation(); 
    const navigate = useNavigate();

    return (
        <div className="h-[400px] mb-16 md:mb-0">
            <div
                className={`h-[370px] relative flex items-end md:-mt-10 justify-center`}
            >
                <div className="h-[320px] w-full bg-primary_color rounded-tl-[2rem] relative">
                    <img
                        className="w-auto h-[280px] absolute scale-x-[-1] top-0 right-0"
                        src={grayLogo}
                        alt="bg"
                    />
                </div>
                <a href={`/doctor-profile/${data._id}`} className="absolute w-full h-full">
                    <img
                        className="w-full h-full bottom-0 object-cover"
                        src={data.image?.location || avatar}
                        alt={data.doctor_name}
                        width={500}
                        height={500}
                    />
                </a>
                <div className="w-11/12 flex flex-col justify-center items-start pl-10 h-[65px] bg-primary_color backdrop-blur-sm bg-opacity-55 rounded-l-full absolute z-30 bottom-[25px] right-0">
                    <h2 className="text-lg font-medium text-white leading-5">
                        {data.doctor_name}
                    </h2>
                    <h3 className="text-white font-light leading-5">
                        {data.department_name}
                    </h3>
                </div>
            </div>
            <button
                onClick={() => navigate(`/doctor-profile/${data._id}`)}
                className="border-primary_color border-2 hover:bg-primary_color hover:text-white py-4 w-full mt-1 rounded-b-[2rem] text-sm font-semibold text-primary_color hover:scale-105 ease-in-out duration-300"
            >
                {t("Book Your Doctor Consultation Now - Easy & Quick!")}
            </button>
        </div>
    )
}

export default DocPrimeCard