import React from 'react'
import internationalpatientimage from '../../assets/international-patient/internationalpatientimage.png'

const ImageContent = () => {
  return (
    <div className='max-w-common mx-auto flex md:flex-row lg:gap-0 gap-5 flex-col py-10 md:py-12 md:mt-5 p-2'>
      <div className='md:w-[40%]'>
        <img src={internationalpatientimage} className='lg:w-[450px] h-full'></img>
      </div>
      <div className='md:w-[60%]'>
        <p className='md:text-[18px] text-[16px] text-left mt-2'>Welcome to the International Patient Services Department at SP Medifort Hospital, where we provide world-class medical care with a seamless experience for patients traveling from across the globe. Our dedicated team ensures personalized assistance at every step, from medical consultations and treatment planning to visa support, travel arrangements, and language interpretation. With state-of-the-art facilities, internationally accredited healthcare professionals, and a patient-centric approach, we strive to make your healthcare journey smooth, comfortable, and stress-free. At SP Medifort Hospital, your well-being is our priority.
        <br/><br/>our dedicated team is here to support you every step of the way. From skilled nursing care to physical therapy and medical social work, we provide comprehensive services tailored to your individual needs.
        </p>
        
      </div>
    </div>
  )
}

export default ImageContent
