
import React from "react";
import cafteria from '../../assets/international-patient/cafteria.png'
import image3 from '../../assets/gallery/image 3.jpg'
import image4 from '../../assets/gallery/image 4.jpg'
import sp2 from '../../assets/specialities/2.jpg'
import gaiter from '../../assets/international-patient/g-gaiter.jpg'
import sp1 from '../../assets/specialities/1.jpg'

const data = [
  { type: "image", src: cafteria, heading: "Lobby" },
  { type: "card", number: "475", description: "Hi-tech beds" },
  { type: "image", src: image3, heading: "Presidential Suite" },
  { type: "card", number: "170", description: "Cubicle ICUs" },
  { type: "image", src: image4, heading: "Operation Theatre" },
  { type: "card", number: "10", description: "Operation Theatres" },
  { type: "card", number: "650+", description: "Multi-level car parking" },
  { type: "image", src: gaiter, heading: "Robotic G-Gaiter" },
  { type: "card", number: "30", description: "Emergency beds" },
  { type: "image", src: sp2, heading: "4K Microscope" },
  { type: "card", number: "25+", description: "Years of health care" },
  { type: "image", src: sp1, heading: "3D Cath Lab" },
];

const ImageCardGrid = () => {
  return (
    <div className="max-w-common grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 mx-auto p-4 mb-10 md:mb-12">
      {data.map((item, index) => (
        <div key={index} className="relative w-full">
          {/* Image Card */}
          {item.type === "image" ? (
            <div className="relative">
              <img src={item.src} alt={item.heading} className="w-full h-[150px] object-cover" />
              <div className="absolute left-0 bg-gray-400 bg-opacity-80 text-sm px-2 py-1 bottom-1">
                {item.heading}
              </div>
            </div>
          ) : (
            /* Numbered Card */
            <div className=" h-[150px] bg-primary_color  p-4  flex flex-col items-center justify-center">
              <h2 className="lg:text-5xl text-4xl font-bold text-white">{item.number}</h2>
              <p className="text-center text-xl lg:text-2xl text-white">{item.description}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ImageCardGrid;
